body,
html {
    cursor: none !important
}

body {
    overflow-x: hidden;
}

#closeButton {
    cursor: none !important;
}

.container1:hover,
.header1:hover {
    cursor: none !important;
}

body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: white
}

.container {
    width: 31vw;
    background-color: #31b1f5;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    color: white;
    position: relative;
    box-shadow: rgba(50, 50, 93, .25) 0 50px 100px -20px, rgba(0, 0, 0, .3) 0 30px 60px -30px, rgba(10, 37, 64, .35) 0px -2px 6px 0 inset;
    border-radius: 10px;
    margin: 10px 0 0 20px
}

.warn {
    font-weight: 700;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blink 1s infinite;
    margin: 0 0 10px 0
}

@keyframes blink {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.box ul {
    padding-left: 20px
}

.box ul li {
    list-style: disc;
    margin-bottom: 5px
}

.err {
    font-size: 15px;
    font-weight: 800
}

.img {
    width: 30px;
    height: auto;
    margin-right: 10px
}

.box ul li {
    list-style: none
}

.red {
    font-weight: bold
}

.warning-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    max-width: 50vw;
    margin-left: 26px;
    animation: zoom 2s infinite;
    display: inline-block
}

@keyframes zoom {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(1)
    }
}

.main {
    display: flex
}

.left {
    width: 50vw;
    height: 100vh;
    position: relative
}

.right {
    width: 50vw;
    height: 100vh;
    background: white
}

p {
    margin-top: -10px
}

ul li {
    padding: 3px
}

ul {
    margin: -10px 0;
}

.margin {
    margin-top: 15px
}

.butns {
    display: flex;
    gap: 5px;
    justify-content: end;
    margin-top: 10px
}

.widt {
    width: 9vw;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: transparent;
    border: 1px solid white !important;
    color: white
}

.wid {
    width: 5vw;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: transparent;
    border: 1px solid white !important;
    color: white
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.right-box {
    max-width: 32vw;
    margin: 0 0 0 230px;
    margin-top: 4vh;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, .09) 0 2px 1px, rgba(0, 0, 0, .09) 0 4px 2px, rgba(0, 0, 0, .09) 0 8px 4px, rgba(0, 0, 0, .09) 0 16px 8px, rgba(0, 0, 0, .09) 0 32px 16px
}

.text {
    text-align: center;
    font-size: 14px;
    font-weight: 800
}

.gif {
    display: flex;
    margin-top: 15px;
    justify-content: space-around;
    max-width: 30vw;
    margin: auto
}

.virus {
    width: 10vw
}

.bottom {
    display: flex;
    align-items: center
}

.conti {
    width: 11vw;
    height: 7vh;
    background: #2a96f6;
    color: white;
    font-size: 21px;
    font-weight: 900;
    border: 0;
    border-radius: 3px;
    margin-left: 6vw
}

.acces {
    text-align: center;
    font-weight: 600;
    font-size: 14px
}

.cancelbtn {
    margin: 0 0 0 20px !important;
}

.width {
    font-weight: 900;
    font-size: 20px
}

strong {
    font-weight: 600;
}

.name strong {
    font-size: 20px;
}

.nav {
    width: 68vw;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    border-radius: 3px
}

footer {
    width: 49vw;
    height: 8vh;
    margin-top: 0;
    display: flex;
    justify-content: center;
    background: #31b1f5;
    box-shadow: rgba(0, 0, 0, .2) 0 18px 50px -10px
}

.ftr-windw img {
    width: 4vw;
    display: flex;
    align-items: center
}

.ftr-windw strong {
    font-size: 20px
}

.ftr-windw {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, .2) 0 18px 50px -10px
}

#popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    font-size: 18px;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: 33vw;
    border-radius: 20px
}

#popup button {
    padding: 10px 20px;
    background-color: #31b1f5;
    color: white;
    border: none;
    font-size: 22px;
    margin: 0 0 0 20px;
    cursor: pointer !important;
    border-radius: 10px;
    height: 66px;
    width: 100%;
    font-weight: 800 !important
}

body {
    font-family: Arial, sans-serif;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0
}

.security-center {
    background-color: white;
    width: 388px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    text-align: center;
    padding: 20px;
    margin: 3rem 0 0 0;
    position: relative;
    z-index: 1111111111
}

.header {
    display: flex;
    align-items: center
}

.header1 {
    align-items: center
}

.header img {
    width: 100px
}

.header h1 {
    font-size: 18px;
    color: #333;
    margin: 0
}

.content p {
    margin: 10px 0;
    font-size: 13px;
    color: #333;
    font-weight: 600
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0
}

.icon img {
    margin: 0 10px;
    width: 50px
}

.content a {
    color: #0066cc;
    text-decoration: none;
    font-size: 20px
}

.actions {
    display: flex;
    justify-content: space-between
}

.actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer
}

.continue {
    background-color: #0066cc;
    color: white
}

.leave {
    background-color: #f1f1f1;
    color: #333
}

.support-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.support-bar {
    background-color: #0078D7;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.support-logo img {
    height: 40px;
    margin-right: 10px;
}

.support-logo {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.support-logo span {
    font-size: 24px;
}

.support-contact a {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.support-bubble-container {
    position: absolute;
    right: 20px;
    bottom: 70px;
}

.support-bubble {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    position: relative;
}

.support-bubble::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, .1));
}

.support-bubble-logo img {
    height: 70px;
    margin-right: 10px;
}

.support-bubble-content {
    display: flex;
    flex-direction: column;
}

.support-bubble-text {
    font-weight: bold;
    font-size: 18px;
    color: #333;
}

.support-bubble-number {
    font-size: 18px;
    color: #0078D7;
    margin-top: 5px;
    font-weight: bold;
}

#continueButton {
    cursor: pointer !important;
}

.gif-popup img {
    max-width: 360px;
    height: 300px;
    border-radius: 5px;
}

.gif4 img {
    width: 30rem;
}

.centerimg img {
    height: 14rem;
    position: relative;
    top: -150px;
}

.left2 {
    width: 30vw;
    display: flex;
    position: relative;
    z-index: 11111;
    box-shadow: rgba(0, 0, 0, .25) 0 54px 55px, rgba(0, 0, 0, .12) 0px -12px 30px, rgba(0, 0, 0, .12) 0 4px 6px, rgba(0, 0, 0, .17) 0 12px 13px, rgba(0, 0, 0, .09) 0px -3px 5px;
    margin: 0 0 0 20px;
    padding: 10px;
    border-radius: 10px;
}

.left2 .icon img {
    margin: 0 10px;
    width: 100px;
}

.gif img {
    width: 100px;
}

.virusimages {
    display: flex;
    position: relative;
    top: 22rem;
    left: -27rem;
    gap: 10px;
    height: 177px;
}

.virusimages .img1 {
    height: 140px;
    margin: 0 0 23px 20px;
    border-radius: 5px;
    position: relative;
    left: -27rem;
    top: -31px;
}

.virusimages .img2 {
    height: 230px;
    margin: -4rem 0 0 4rem;
    border-radius: 5px;
    position: relative;
    top: -35px;
    left: 63px;
}

.left3 {
    position: absolute;
    z-index: 99999999999;
    left: 238px;
    top: 182px;
}

.img3 {
    height: 100px;
    left: -97px;
    position: relative;
    top: 1px;
}

.img4 {
    position: relative;
    top: -483px;
    left: -147px;
    z-index: 9999999999;
    width: 195px;
    height: 106px;
    border-radius: 5px;
}

.img30 {
    position: relative;
    left: -53rem;
    height: 158px;
    top: -25px;
    border-radius: 5px;
}

.img60 {
    position: relative;
    left: -101rem;
    height: 157px;
    top: -36px;
    width: 257px;
    border-radius: 5px;
}

.img50 {
    position: relative;
    left: -64rem;
    top: -479px;
    z-index: 999999999999;
    height: 95px;
    border-radius: 5px;
}

.img70 {
    height: 100px;
    left: -10rem;
    position: relative;
    border-radius: 5px;
}